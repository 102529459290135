














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.date-examples {
  max-height: 150px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 16px;

  ul {
    padding: 0 0 0 16px;
    margin: 0;
    margin-top: 4px;
  }
}
